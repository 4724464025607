<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        class="px-0 "
        style="height:140px;"
        :style="{background: $vuetify.theme.themes.light.primary}"
      >
        <v-row
          class="px-3 px-md-6"
        >
          <v-col
            cols="12"
            md="2"
            class="mt-2 "
          >
            <v-card
              elevation="2"
              height="150px"
              class="d-flex justify-center align-center"
            >
              <v-card-title>
                <v-img
                  contain
                  height="130px"
                  src="@/assets/logo.svg"
                />
              </v-card-title>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="10"
            class="mt-2"
          >
            <v-card
              elevation="2"
              style="height:100%"
            >
              <v-card-title style="height:100%">
                <v-row align="center">
                  <v-col>
                    <stat-title
                      :stat="reportes.pedidos_asignados"
                      title=" Pedidos asignados"
                    />
                  </v-col>
                  <v-col class="primary--text">
                    <stat-title
                      :stat="reportes.pedidos_completados"
                      title=" Pedidos completados"
                    />
                  </v-col>
                  <v-col class="primary--text">
                    <stat-title
                      :stat="reportes.remitos_generados"
                      title=" Remitos generados"
                    />
                  </v-col>
                  <v-col class="primary--text">
                    <stat-title
                      :stat="reportes.remitos_entregados"
                      title=" Remitos entregados"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <!-- Tabla de Pedidos -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-14 px-md-3">
      <v-col
        cols="12"
        md="5"
      >
        <v-card
          elevation="2"
        >
          <v-card-title>
            <div
              class="ml-2 font-weight-medium text-h6 greyTxt--text pt-2 cursor-pointer"
              @click="openClientes"
            >
              Clientes
              <right-arrow-icon
                :width="6"
                class="ml-1"
              />
            </div>
          </v-card-title>
          <!-- Tabla de Pedidos -->
          <generic-table
            :loading="loading_clientes"
            :headers="headers_clientes"
            :items="clientes"
            height="45vh"
            :options="{
              'itemsPerPage':6
            }"
            :actions="actions_clientes"
            @openPedidos="openPedidos"
          />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="7"
      >
        <v-card
          elevation="2"
        >
          <v-card-title>
            <div
              class="ml-2 font-weight-medium text-h6 greyTxt--text pt-2 cursor-pointer"
              @click="openRemitos"
            >
              Remitos
              <right-arrow-icon
                :width="6"
                class="ml-1"
              />
            </div>
          </v-card-title>
          <!-- Tabla de Remitos -->
          <generic-table
            :loading="loading_remitos"
            :headers="headers_remitos"
            :items="remitos"
            height="45vh"
            :options="{
              'itemsPerPage':6
            }"
            :sort-by="['id_remito']"
            :actions="actions_remitos"
            @openRemito="openRemito"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import StatTitle from './components/StatTitle.vue'
  export default {
    name: 'DashboardView',
    components: {
      StatTitle,
    },
    data () {
      return {
        // Pedidos
        actions_clientes: [
          {
            icon: 'mdi-eye',
            title: 'Pedidos',
            color: 'primary',
            emit: 'openPedidos',
          },
        ],

        actions_remitos: [
          {
            icon: 'mdi-eye',
            title: 'Detalle',
            color: 'primary',
            emit: 'openRemito',
          },
        ],
      }
    },

    computed: {
      group: get('user/group'),
      clienteInterno: get('user/clienteInterno'),
      remitos: get('remitos/items'),
      headers_remitos: get('remitos/dashboardHeader'),
      loading_remitos: get('remitos/loading'),
      count_remitos: get('remitos/count'),
      clientes: get('clientes/items'),
      headers_clientes: get('clientes/headers'),
      loading_clientes: get('clientes/loading'),
      reportes: get('user/reportes'),
    },
    mounted () {
      this.initialize()
    },
    methods: {
      getDataClientes: call('clientes/getData'),
      getDataRemitos: call('remitos/getData'),
      getReportes: call('user/getReportes'),

      async initialize () {
        if (this.group === 'clienteInterno') {
          const idDestino = this.clienteInterno.unidad && this.clienteInterno.unidad !== '' ? `/${this.clienteInterno.unidad}` : ''
          this.$router.push({ path: `/cliente/${this.clienteInterno.id_cliente}${idDestino}` }).catch(error => {
            console.log(error.message)
          })
        } else {
          return Promise.all([this.getDataRemitos(), this.getDataClientes(), this.getReportes()])
        }
      },
      openPedidos (details) {
        this.$router.push(`/pedidos/${details.id_cliente}/${details.raz_soc}`)
      },
      openRemito (details) {
        console.log(details)
        this.$router.push(`/remito/${details.id_control}/${details.idemcmc}/${details.id_pedido}`)
      },
      openClientes () {
        this.$router.push('/clientes')
      },
      openRemitos () {
        this.$router.push('/remitos')
      },
    },
  }
</script>
